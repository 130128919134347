@import "../../styles/font.scss";

.container {
  @media (max-width: 601px) {
    min-width: 90vw !important;
    margin: 0px;
  }

  @media only screen and (max-width: 901px) and (min-width: 601px) {
    min-width: 80vw !important;
  }

  @media only screen and (max-width: 1201px) and (min-width: 901px) {
    min-width: 60vw !important;
  }

  background-color: var(--white);

  :global(.p-dialog-header) {
    padding: var(--spacing-3) !important;
  }

  .header {
    :global(.p-dialog-title) {
      @extend %h4Bold;
      color: var(--black-2);
      text-transform: uppercase;
    }
    :global(.p-dialog-header-icon) {
      color: var(--black-2);
      &:enabled:hover {
        background: none;
        color: var(--black-2);
      }
      &.p-link,
      &:focus {
        box-shadow: none;
      }
    }

    // :global(.p-dialog-header-icons) {
    //   width: 35px !important;
    //   height: 35px !important;
    // }

    border-bottom: 1px solid var(--grey-3);
  }

  :global(.p-dialog-content) {
    padding: var(--spacing-3);

    .descriptionContainer {
      margin-top: var(--spacing-3);
      p {
        @extend %h5Bold;
        margin: 0px;
        padding-bottom: var(--spacing-3);
      }
    }

    .inputsContainer {
      @media (max-width: 600px) {
        width: 80vw !important;
        margin: 0px;

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        :global(.p-inputwrapper) {
          margin-bottom: var(--spacing-3) !important;
        }

        .inputWrapper {
          display: flex;

          :global(.p-inputtext) {
            flex-grow: 1;
          }
        }

        :global(.p-inputwrapper) {
          display: flex;

          :global(.p-inputtext) {
            flex-grow: 1;
          }
        }
      }

      @media only screen and (max-width: 725px) and (min-width: 601px) {
        justify-content: space-between;
      }

      @media (min-width: 725px) {
        .inputWrapper {
          margin-left: var(--spacing-4) !important;
        }
      }

      display: flex;
      justify-content: flex-start;
    }
  }

  :global(.p-dialog-footer) {
    padding: var(--spacing-3) !important;
    padding-top: var(--spacing-2) !important;

    .customFooterContainer {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.passwordWrapper {
  @extend %h5;
  line-height: var(--line-height-5);
  color: var(--dark-1);
  border-radius: var(--border-radius-2);
  border-color: var(--grey-4);
  padding: calc(var(--spacing-1) * 1.5);
  width: 100%;

  &:enabled {
    &:focus,
    &:hover {
      box-shadow: none;
      border-color: var(--grey-4);
    }
  }
}

.otpDisclaimer {
  @extend %h6;
  text-align: center;
  background-color: #e4f8f0;
  margin-top: var(--spacing-1);
}