@import "../../styles/font.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-3);
  width: 228px;
  height: 49px;
  padding: var(--spacing-4);
  background: var(--black-8);
  color: var(--white);
  cursor: pointer;

  @extend %h3;

  i {
    color: var(--white);
  }

  @media (max-width: 480px) {
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 50%;
    // margin-right: var(--spacing-3);
    font-size: var(--spacing-3);
    gap: 0;

    i {
      display: none;
    }
  }
}
