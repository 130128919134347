@import "../../../styles/font.scss";

:global {
  :local(.container) {
    .p-input-icon-left {
      display: flex;
      input {
        padding-left: var(--spacing-6);
      }
    }
  }
}
.container {
  width: 100%;
  i {
    top: 56%;
  }
}
.inputWrapper {
  @extend %h5;
  line-height: var(--line-height-5);
  color: var(--dark-1);
  border-radius: var(--border-radius-2);
  border-color: var(--grey-4);
  padding: calc(var(--spacing-1) * 1.5);
  width: 100%;

  &:enabled {
    &:focus,
    &:hover {
      box-shadow: none;
      border-color: var(--grey-4);
    }
  }
}

.errorWrapper {
  border-color: var(--red);
  &:enabled {
    &:focus,
    &:hover {
      border-color: var(--red);
    }
  }
}

.label {
  @extend %h5SemiBold;
  margin-bottom: calc(var(--spacing-1) * 0.5);
}

.errorLabel {
  color: var(--red);
}

.icon {
  font-size: var(--spacing-3);
}

.fromToContainer {
  display: flex;
  gap: var(--spacing-3);
}