:global {
  :local(.app_body_container) {
    .p-dialog-mask {
      z-index: 2 !important;
    }
  }
}

.app_container {
  display: flex;
  background-color: #f5f5f5;
  height: 100%;
  flex-direction: column;
}

.app_navbar_container {
  display: flex;
  flex-direction: column;
  border-bottom: #e3e3e3 2px solid;
  width: 100%;
  height: 48px;

  @media (max-width: 480px) {
    z-index: 99999;
    border-bottom: none;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

.app_main_container {
  display: flex;
  transition: 0.4s;
}

.app_left_side_menu_container {
  display: flex;
  min-width: 130px;
  height: calc(100vh - 48px);
  background-color: #2e2e2e;
  overflow-y: auto;

  @media (max-width: 480px) {
    min-width: 0;
    width: 0;
    position: absolute;
    z-index: 3;
    top: 0;
    height: 100vh;

    overflow: hidden;
    transition: min-width 0.2s ease-out;

    &.isOpen {
      display: flex;
      min-width: 130px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.app_body_container {
  display: flex;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 48px);
  background-color: var(--white);

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
