.container {
  @media (max-width: 480px) {
    margin-top: var(--spacing-3);
  }

  :global(.p-selection-column) {
    display: none !important;
  }
  // :global(tr[data-pc-section="headerrow"]:nth-child(2)) {
  //   display: none !important;
  // }

  :global(.p-selectable-row.p-highlight) {
    background-color: var(--grey-10) !important;
  }

  :global {
    :local(.tableWrapper) {
      .p-datatable-wrapper {
        tr[data-pc-section="headerrow"]:nth-child(2) {
          th:first-child {
            display: none !important;
          }
        }
      }
    }
  }
}
