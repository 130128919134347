@import "../../styles/font.scss";

.container {
  padding: var(--spacing-5);

  @media (max-width: 480px) {
    padding: 0;

    .titleContainer {
      padding: var(--spacing-5);
    }
  }

  .title {
    @extend %h2;
    margin-bottom: var(--spacing-5);
  }

  .content {
    margin-top: var(--spacing-4);

    @media (max-width: 480px) {
      margin-top: 0;
    }

    .section {
      &_title {
        @extend %h4Bold;
        margin-bottom: var(--spacing-2);
        text-transform: uppercase;

        @media (max-width: 480px) {
          padding: 0 var(--spacing-5);
        }
      }
      &_description {
        @extend %h6;
        margin-bottom: var(--spacing-3);
        @media (max-width: 480px) {
          padding: 0 var(--spacing-5);
        }
      }
    }
  }
}
