@import "../../styles/font.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-3);
  width: 209px;
  height: 100%;
  padding: var(--spacing-4);
  padding-right: 0;

  @media (max-width: 480px) {
    &.languagesEnabled {
      padding-left: 0;
    }
  }
  .dropdown {
    border: none;

    :global(.p-dropdown-label.p-inputtext) {
      @extend %h3;
      color: var(--black);
      @media (max-width: 480px) {
        font-size: var(--spacing-3);
      }
    }
    :global(.p-dropdown-trigger) {
      width: var(--spacing-6);
      color: var(--black);
    }
  }
}
