@import "../../../styles/font.scss";

.container {
  .checkboxGrid {
    display: grid;
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-3);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: var(--spacing-3) 0;
    width: 103%;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    .checkboxContainer {
      display: flex;
      align-items: center;
    }
    .label {
      @extend %h5;
    }
  }
}
:global {
  .p-checkbox {
    display: flex;
    align-items: center;
    .p-checkbox-box {
      border-radius: var(--border-radius-2);
      border: 1px solid var(--black-3);
      width: var(--spacing-3);
      height: var(--spacing-3);
      &.p-highlight {
        border: none;
        background: var(--accent);
      }
      svg {
        width: var(--line-height-2);
        height: var(--line-height-2);
      }
    }
  }
}

:global {
  .p-radiobutton {
    display: flex;
    align-items: center;
    .p-radiobutton-box {
      border: 1px solid var(--black-3);
      width: var(--spacing-3);
      height: var(--spacing-3);
      &.p-highlight {
        border: none;
        .p-radiobutton-icon {
          transform: translateZ(0) scale(0.5, 0.5);
        }
      }
    }
  }
}
