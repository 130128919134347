// @import './fonts.scss';
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55");
@import "./palette.scss";

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: "Helvetica Neue";
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.72) !important;
  z-index: 999999 !important;
}

.p-dialog-content {
  flex-grow: 0 !important;
  background-color: var(--white) !important;
}

.p-dialog-footer {
  padding-top: 0.5rem !important;
}

.p-dialog-header {
  padding-bottom: 0.5rem !important;
  .p-dialog-header-icons {
    padding-top: 3px;
  }

  .p-icon {
    width: 11px;
    height: 11px;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}