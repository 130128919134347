@import "../../../../styles/font.scss";

:global {
  :local(.wrapper) {
    .p-datatable-wrapper {
      .p-datatable-emptymessage {
        @extend %h6;
      }
      .p-datatable-thead {
        tr {
          th.p-selection-column,
          th.header-column,
          th.header-column.p-highlight {
            background: var(--black-10);
            color: var(--white);
            box-shadow: none;
            padding: var(--spacing-3);
            height: 47px;
            @extend %h5;

            @media (max-width: 480px) {
              border-width: 0;
              border: none;
            }

            span {
              white-space: nowrap;
            }

            &.p-selection-column {
              padding-left: var(--spacing-4);
            }
            .p-checkbox {
              width: var(--spacing-4);
              height: var(--spacing-4);

              @media (max-width: 480px) {
                width: var(--spacing-4);
                height: var(--spacing-4);
              }
              .p-checkbox-box {
                border: 2px solid var(--grey-16);
                border-radius: var(--border-radius-3);
                width: var(--spacing-4);
                height: var(--spacing-4);

                @media (max-width: 480px) {
                  border: 1px solid var(--grey-16);
                  width: var(--spacing-4);
                  height: var(--spacing-4);
                }

                &.p-highlight {
                  border: 2px solid var(--accent);
                  background: var(--accent);
                }
              }
              svg {
                display: block;
              }
              &:not(.p-checkbox-disabled) {
                .p-checkbox-box.p-focus {
                  box-shadow: unset;
                }
              }
            }
            svg {
              display: none;
            }

            &.p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
            &.p-sortable-column:not(.p-sortable-disabled):hover {
              background: var(--black-10);
              color: var(--white);
            }

            .p-column-header-content {
              &::after {
                font-family: "primeicons";
                margin-left: var(--spacing-2);
                font-size: var(--spacing-2);
              }
            }
            &[aria-sort="ascending"] {
              .p-column-header-content {
                &::after {
                  content: "\e91c";
                }
              }
            }
            &[aria-sort="descending"] {
              .p-column-header-content {
                &::after {
                  content: "\e919";
                }
              }
            }
          }
          th.p-filter-column {
            padding: var(--spacing-3);
            height: 47px;
            @extend %h5;

            @media (max-width: 480px) {
              border-width: 0;
              border: none;
            }

            button {
              display: none;
            }
            span {
              white-space: nowrap;
            }
            .p-checkbox {
              width: var(--spacing-5);
              height: var(--spacing-5);

              @media (max-width: 480px) {
                width: var(--spacing-4);
                height: var(--spacing-4);
              }
              .p-checkbox-box {
                border: 2px solid var(--grey-16);
                border-radius: var(--border-radius-3);
                width: var(--spacing-5);
                height: var(--spacing-5);

                @media (max-width: 480px) {
                  // border: 1px solid var(--black-5);
                  border: 1px solid var(--grey-16);
                  width: var(--spacing-4);
                  height: var(--spacing-4);
                }

                &.p-highlight {
                  border: 2px solid var(--accent);
                  background: var(--accent);
                }
              }
              svg {
                display: block;
              }
              &:not(.p-checkbox-disabled) {
                .p-checkbox-box.p-focus {
                  box-shadow: unset;
                }
              }
            }
            svg {
              display: none;
            }
            .p-inputtext {
              @extend %h5;
              line-height: var(--line-height-5);
              color: var(--dark-1);
              border-radius: var(--border-radius-6);
              border-color: var(--grey-4);
              padding: calc(var(--spacing-1) * 1.5);
              width: 100%;

              &:enabled {
                &:focus,
                &:hover {
                  box-shadow: none;
                  border-color: var(--grey-4);
                }
              }
            }
          }
        }
      }
      .p-datatable-tbody {
        tr {
          cursor: pointer;
          &:hover {
            background-color: var(--yellow) !important;
          }
          &:nth-of-type(odd) {
            background: var(--grey-17);
          }

          &:nth-of-type(even) {
            background: var(--white);
          }

          &.custom-highlight {
            background: var(--grey-10);
          }

          @media (max-width: 480px) {
            &:nth-of-type(odd) {
              // background: var(--white);
            }
          }

          td {
            padding-left: 14px;
            @extend %h5;
            line-height: var(--line-height-3);
            color: var(--grey-130);

            @media (max-width: 480px) {
              padding: var(--spacing-3);
              // border: none;

              &.p-selection-column {
                padding-left: 1rem;
              }
            }

            .p-checkbox {
              width: var(--spacing-5);
              height: var(--spacing-5);
              @media (max-width: 480px) {
                width: var(--spacing-4);
                height: var(--spacing-4);
              }
              .p-checkbox-box {
                border: 2px solid var(--grey-16);
                border-radius: var(--border-radius-3);
                width: var(--spacing-5);
                height: var(--spacing-5);

                @media (max-width: 480px) {
                  // border: 1px solid var(--black-5);
                  border: 1px solid var(--grey-16);
                  width: var(--spacing-4);
                  height: var(--spacing-4);
                }

                &.p-highlight {
                  border: 2px solid var(--accent) !important;
                  background: var(--accent) !important;
                }
              }
              &:not(.p-checkbox-disabled) {
                .p-checkbox-box.p-focus {
                  box-shadow: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}
