@import "../../styles/font.scss";

.container {
  padding: var(--spacing-5);
  width: 100%;

  @media (max-width: 480px) {
    padding: 0;

    .titleContainer {
      padding: var(--spacing-5);
    }
  }

  .title {
    @extend %h2;
    margin-bottom: var(--spacing-5);
  }

  .content {
    margin-top: var(--spacing-4);

    @media (max-width: 480px) {
      margin-top: 0;
    }

    .manualSection {
      margin-top: var(--spacing-6);
      &_title {
        @extend %h4Bold;

        &.area {
          @extend %h3Bold;
        }

        margin-bottom: var(--spacing-2);
        text-transform: uppercase;

        @media (max-width: 480px) {
          padding: 0 var(--spacing-5);
        }
      }
      &_description {
        @extend %h5;
        margin-bottom: var(--spacing-3);
        @media (max-width: 480px) {
          padding: 0 var(--spacing-5);
        }

        &_empty {
          @extend %h5;
          font-style: oblique;
        }
      }

      &_table {
        // padding: var(--spacing-3) 0;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: var(--spacing-4);
        width: 50%;

        @media (max-width: 1024px) {
          width: 100%;
        }
        @media (max-width: 480px) {
          margin-top: 0;
          box-shadow: none;
        }
      }
      .crudContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .spinner {
    justify-content: flex-end;
    i {
      font-size: var(--spacing-5);
    }
  }
}
