@import '../../../styles/font.scss';

.container {
    width: 100%;
    i {
        top: 56%;
    }
}
.inputWrapper {
    @extend %h5;
    line-height: var( --line-height-5);
    color: var(--dark-1);
    border-radius: var(--border-radius-2);
    border-color: var(--grey-4);
    padding: calc(var(--spacing-1) * 1.5);
    width: 100%;
    &:enabled:focus {
        box-shadow: none;
    }
}