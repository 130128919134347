
.content {
   i {
    font-size: var(--spacing-3);
    opacity: 0.3;

    &.highlight {
      opacity: 1;
    }
   }
}