@import "../../../styles/font.scss";

.container {
  display: flex;
  justify-content: flex-end;

  :global {
    :local(.wrapper) {
      .p-paginator-pages {
        .p-paginator-page {
          width: var(--spacing-5);
          height: var(--spacing-5);
          min-width: var(--spacing-5);
          box-shadow: unset;

          &:not(.p-highlight):hover {
            background: transparent;
            color: inherit;
          }

          &.p-highlight {
            background: var(--orange) !important;
            border-radius: var(--border-radius-6);
            font-weight: var(--font-weight-700);
          }
        }
      }
    }
  }

  .wrapper {
    .buttonLabel {
      @extend %h5;
      line-height: var(--line-height-5);
      color: var(--grey-text);
      margin: 0 var(--spacing-1);
    }

    .buttonIcon {
      color: var(--orange);
    }

    :global(.p-paginator-first),
    :global(.p-paginator-last),
    :global(.p-paginator-next),
    :global(.p-paginator-prev) {
      box-shadow: unset;
      &:not(.p-disabled):not(.p-highlight):hover {
        background: transparent;
        color: inherit;
      }
      width: 35px;
      min-width: auto;
      margin: 0;
    }
    :global(.p-paginator-pages .p-paginator-page) {
      @extend %h5;
    }
  }

  .rowsPerPage {
    @extend %h5;
    line-height: var(--line-height-3);
    color: var(--grey-text);
    display: flex;
    align-items: center;
    margin-right: var(--spacing-9);

    .dropdown {
      margin: 0 var(--spacing-3);
      height: var(--spacing-6);
      display: flex;
      align-items: center;
      border-radius: 0;
      border: none;
      background-color: var(--grey-2);
      box-shadow: unset;

      :global(.p-dropdown-trigger) {
        display: none;
      }
      :global(.p-dropdown-label.p-inputtext),
      :global(.p-dropdown-item) {
        padding: var(--spacing-2);
        @extend %h5;
        line-height: var(--line-height-5);
      }
      :global(.p-dropdown-items) {
        padding: 0;
      }
    }
  }
  .currentPageReport {
    @extend %h5;
    line-height: var(--line-height-3);
    color: var(--grey-text);
  }
}
