:global {
  :local(.container) {
    .p-datatable {
      .p-datatable-tbody > tr > td .p-row-toggler {
        &:enabled:hover {
            background: none;
        }
        &:focus {
            box-shadow: none;
        }
      }
    }
  }
}

.reorderColumn {
  width: var(--spacing-6);
  vertical-align: middle;
}
