@import "../../../styles/font.scss";

:global {
    :local(.tooltip) {
        .p-tooltip-text {
            @extend %h5;
        }
    }
}

.container {
  display: flex;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  .itemContainer {
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    box-sizing: border-box;
    width: 100px;

    &.highlight {
      &:not(:last-child) {
        &::before {
          border-color: var(--grey-20);
        }
      }

      .item {
        .number {
          background-color: var(--orange);
          color: var(--white);
        }
      }
    }

    &.isDone {
      &:not(:last-child) {
        &::before {
          border-color: var(--grey-20);
        }
      }
      .item {
        .number {
          background-color: var(--grey-20);
          color: var(--white);
        }
      }
    }

    // &:not(:last-child) {
      &::before {
        content: " ";
        border: 4px solid var(--grey-19);
        width: 102%;
        left: 54%;
        top: 22px;
        display: block;
        position: absolute;
      }
    // }
    &:last-child {
      &::before {
        width: 33%;
        border-color: var(--white);
      }
    }

    .item {
      background: transparent;
      padding-top: 5px;
      transition: box-shadow 0.2s;
      border-radius: 6px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      text-decoration: none;

      .number {
        @extend %h5Bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        background-color: var(--grey-21);
        width: 40px;
        height: 40px;
        border: 4px solid var(--white);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        z-index: 99;
      }
    }

    .label {
      @extend %h6Bold;
      //   position: absolute;
      //   top: 48px;
      color: var(--grey-130);
      // white-space: nowrap;
      text-align: center;
    }
  }
}
