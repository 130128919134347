@import "../../styles/font.scss";

:global {
  :local(.steps) {
    .p-steps {
      ul {
        @media (max-width: 480px) {
          flex-direction: column;
          gap: 22px;
          // align-items: center;
        }
        .p-steps-item {
          // width: 130px;
          @media (max-width: 480px) {
            width: auto;
            display: flex;
            justify-content: flex-start;
          }

          &.p-disabled {
            opacity: unset;
          }
          .p-menuitem-link {
            background: transparent;
            // width: 134px;
            padding-top: 5px;

            @media (max-width: 480px) {
              width: auto;
              flex-direction: row;
            }
          }
          &.p-highlight,
          &.highlight {
            &:before {
              border-color: var(--grey-20);
            }

            .p-steps-number {
              color: var(--white);
              background-color: var(--orange);
            }
          }
          &.isDone {
            .p-steps-number {
              color: var(--white);
              background-color: var(--grey-20);
            }
            &:before {
              border-color: var(--grey-20);
            }
          }
          &:before {
            border: 4px solid var(--grey-19);
            width: 92%;
            left: 54%;
            top: 55%;

            @media (max-width: 480px) {
              border: 2px solid var(--grey-19);
              width: 0;
              height: 50%;
              left: 10%;
              top: 136%;
            }
          }
          &:last-child {
            &::before {
              display: none;
            }
          }

          .p-steps-title {
            @extend %h6Bold;
            // max-width: 130px;
            // text-overflow: ellipsis;
            // overflow: hidden;
            @media (max-width: 480px) {
              margin-top: 0;
              margin-left: var(--spacing-2);
            }
          }

          .p-steps-number {
            width: 40px;
            height: 40px;
            border: 4px solid var(--white);
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
            background-color: var(--grey-21);
            color: var(--black);
            @extend %h5Bold;
          }
        }
      }
    }
  }
}

.container {
  // min-width: 0;
  display: none;
  position: relative;
  // top: 46px;
  // right: 0;
  // height: calc(100vh - 46px);
  background-color: var(--white);
  border-left: 2px solid var(--grey-18);

  // overflow: hidden;
  transition: min-width 0.2s ease-out;

  &.isOpen {
    // min-width: 50%;
    display: block;
    width: 55%;
    // z-index: 99999;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .close {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: var(--spacing-2);
    padding: var(--spacing-2) var(--spacing-3);
    i {
      cursor: pointer;
      // border-radius: 50%;
      // padding: var(--spacing-1);
      // border: 1px solid black;
      // font-size: var(--spacing-3);
    }

    .document_title {
      @extend %h4SemiBold;
    }

    @media (max-width: 480px) {
      padding-top: var(--spacing-3);
    }
  }

  .steps {
    // height: calc(100% - 64px);
    // overflow-y: auto;

    // @media (max-width: 480px) {
    //   height: auto;
    // }

    &_mobile_wrapper {
      padding: var(--spacing-2) var(--spacing-3);
    }

    width: 100%;

    &_mobile {
      display: none;
      @media (max-width: 480px) {
        display: grid;
        grid-template-columns: 1fr 2.5fr 4fr 1.5fr;
        column-gap: var(--spacing-3);
        // justify-content: space-between;
        border: 1px solid var(--grey-4);
        border-radius: var(--border-radius-2);

        .step {
          margin: var(--spacing-3);
        }

        i {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: var(--spacing-3);
        }
      }
    }

    &_container {
      @media (max-width: 480px) {
        display: none;
      }
      &_mobile {
        display: none;
        @media (max-width: 480px) {
          display: flex;
          // gap: var(--spacing-3);

          justify-content: space-between;
          border: 1px solid var(--grey-4);
          border-radius: var(--border-radius-2);
          padding: var(--spacing-2) var(--spacing-3);
          margin: var(--spacing-2) var(--spacing-3);

          // &_wrapper {
          //   margin: var(--spacing-3);
          // }

          i {
            margin-top: var(--spacing-4);
          }
        }
      }
    }

    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 4px solid var(--white);
      border-radius: 50%;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

      color: var(--white);
      background-color: var(--orange);
      @extend %h5SemiBold;

      &_description {
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %h5SemiBold;
      }

      &_borderContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 var(--spacing-3);
      }

      &_border {
        width: 100%;
        height: 4px;
        background-color: var(--grey-20);
        border-radius: var(--border-radius-4);
      }

      &_count {
        display: flex;
        align-items: center;
        justify-content: center;
        // border-radius: var(--border-radius-4);
        // border: 1px solid var(--grey-10);
        // max-height: 40px;
        min-width: 54px;

        @extend %h4;
      }
    }
  }

  .historyFooter {
    position: absolute;
    height: 50px;
    background: var(--grey-1);
    width: 100%;
    bottom: 49px;
    padding: 0 var(--spacing-5);
    display: flex;
    align-items: center;

    &__left {
      @extend %h4Bold;
    }
    &__right {
      @extend %h4;
    }
  }
  .footer {
    position: absolute;
    height: 50px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    background: var(--white);
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing-5);

    @media (max-width: 480px) {
      padding: 0 var(--spacing-2);
    }

    &_left {
      display: flex;
      gap: var(--spacing-3);
    }
    &_right {
      display: flex;
      gap: var(--spacing-3);

      @media (max-width: 480px) {
        display: none;
      }

      &_mobile {
        display: none;
        position: relative;
        @media (max-width: 480px) {
          display: block;
        }
      }
    }
    &_moreActions {
      position: absolute;
      width: 100%;
      background: var(--white);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      bottom: 34px;
      padding: var(--spacing-3) var(--spacing-2);
    }

    &_action {
      display: flex;
      align-items: center;
      gap: var(--spacing-2);
      width: 100%;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: var(--spacing-4);
      }

      @extend %h5SemiBold;

      i {
        font-size: var(--spacing-3);
      }
    }
  }

  .tab {
    &_container {
      height: calc(100% - 185px);
      overflow-y: auto;

      @media (max-width: 480px) {
        height: calc(100% - 108px);
      }
    }
  }
}

:global {
  :local(.tab) {
    .p-tabview-nav-container {
      width: 100% !important;
    }
  }
}
