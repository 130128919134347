:root {
  // colors
  --primary: #323130;
  --white: #ffffff;
  --black: #000000;
  --black-2: #1a1a1a; // Dialog header
  --black-3: #212121;
  --black-4: #1e1e1e; // Accordion header right link
  --black-5: #323130; // Input switch slider
  --black-6: #4f4f4f;
  --black-7: #101828;
  --black-8: #2e2e2e;
  --black-9: #030303;
  --black-10: #4e4e4e;
  --dark-1: #605e5c;
  --grey-1: #e8e8e8; // AddTemplate border
  --grey-2: #f3f2f1; // Paginator show item dropdown
  --grey-3: #d4d4d4; // Dialog description border bottom
  --grey-4: #bfbfbf; // Input border
  --grey-5: #afafaf; // Sortable tree node border
  --grey-6: #f4f4f4; // Sortable tree node add content background
  --grey-7: #f8f9fa; // Secondary table header - process template definition detail metadata row expandend table
  --grey-8: #dedede; // Workflow panel border
  --grey-9: #686868;
  --grey-10: #bababa;
  --grey-11: #f6f6f6;
  --grey-12: #707070;
  --grey-13: #e4e4e4;
  --grey-14: #e0e0e0;
  --grey-15: #d9d9d9;
  --grey-16: #c9c9c9;
  --grey-17: #efefef;
  --grey-18: #e3e3e3;
  --grey-19: #e6e6e6;
  --grey-20: #9e9e9e;
  --grey-21: #e2dfdd;
  --grey-22: #545454;
  --grey-text: #565656; // Paginator show item text
  --grey-30: #edebe9;
  --grey-31: #cecece;
  --grey-130: #605e5c;
  --grey-190: #201f1e;
  --orange: #f3a945; // Paginator angle icons
  --accent: #3b84aa;
  --yellow: #fed300;
  --yellow-2: #ffd300;
  --red: #d13354; // Error (the shade came from Eni's logo)
  --red-2: #da3b01; // Error (the shade came from Eni's logo)
  // font weight
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-900: 900;
  // line-height
  --line-height-1: 9px;
  --line-height-2: 10px;
  --line-height-2-5: 13px;
  --line-height-3: 16px;
  --line-height-4: 18px;
  --line-height-5: 20px;
  --line-height-6: 28px;
  --line-height-7: 40px;
  // spacing
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 24px;
  --spacing-6: 32px;
  --spacing-7: 40px;
  --spacing-8: 48px;
  --spacing-9: 64px;
  --spacing-10: 80px;
  --spacing-11: 96px;
  // radius
  --border-radius-2: 2px;
  --border-radius-3: 3px;
  --border-radius-4: 4px;
  --border-radius-6: 6px;
  --border-radius-8: 8px;
}
