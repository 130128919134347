@import "../../../styles/font.scss";

:global {
  :local(.container) {
    @media (max-width: 480px) {
      .p-dropdown {
        background: var(--grey-2);
      }
    }
    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: unset;
    }
  }
}
.wrapper {
  width: 100%;
}
.wrapper,
.panelWrapper {
  @extend %h5;
  line-height: var(--line-height-5);
  color: var(--dark-1);
  border-radius: var(--border-radius-2);
  border-color: var(--grey-4);
  z-index: 999999 !important;

  :global(.p-dropdown-header) {
    background: white;
  }

  :global(.p-dropdown-items .p-dropdown-item) {
    border-radius: var(--border-radius-2);
    padding: var(--spacing-2);
  }
  :global(.p-dropdown-items .p-dropdown-item.p-highlight) {
    background: var(--orange);
    color: var(--primary);
  }

  :global(.p-dropdown-header .p-dropdown-filter-container svg) {
    left: var(--spacing-3);
  }

  :global(.p-dropdown-header .p-dropdown-filter-container input) {
    @extend %h5;
    line-height: var(--line-height-5);
    color: var(--dark-1);
    border-radius: unset;
    padding-left: 40px;
    padding-right: 12px;
    padding-bottom: var(--spacing-2);
    padding-top: var(--spacing-2);
    background-color: var(--grey-30);
    border-color: var(--grey-30);

    &:enabled:focus {
      box-shadow: none;
      border-color: var(--grey-30);
    }
  }

  :global(.p-dropdown-trigger svg) {
    width: var(--spacing-3);
    height: var(--spacing-3);
  }
  :global(.p-dropdown-trigger) {
    justify-content: flex-end;
    padding-right: var(--spacing-3);
  }
  :global(.p-dropdown-label.p-inputtext),
  :global(.p-dropdown-item) {
    padding: calc(var(--spacing-1) * 1.5);
    @extend %h5;
    line-height: var(--line-height-5);
  }
  :global(.p-dropdown-items) {
    padding: var(--spacing-3);
  }
}
