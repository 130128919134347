@import "../../styles/font.scss";

.container {
  border-bottom: 1px solid var(--grey-3);

  &:last-child {
    border-bottom: none;
  }

  .headerContainer {
    @media (max-width: 767px) {
      flex-direction: column;
      padding-top: 12px;
    }

    display: flex;
    justify-content: space-between;

    .authorInfoContainer {
      display: flex;
    }

    .dateInfoContainer {
      display: flex;
    }

    .infoLabel {
      @media (max-width: 767px) {
        margin-top: 0;
      }

      color: var(--grey-190);
      @extend %h5Bold;
      line-height: 1;
    }

    .infoValue {
      @media (max-width: 767px) {
        margin-top: 0;
      }

      color: var(--grey-190);
      @extend %h5;
      line-height: 1;
      padding-left: 5px;
    }
  }

  .subHeaderContainer {
    display: flex;
    align-items: center;

    .titleContainer {
      display: flex;
      align-items: center;

      i {
        @media (max-width: 767px) {
          text-size: 8px;
        }

        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }

      p {
        @media (max-width: 767px) {
          font-weight: var(--font-weight-700);
          font-size: calc(var(--spacing-3) + (var(--spacing-1) * 0.5));
          line-height: var(--line-height-4);
        }

        color: var(--grey-190);
        @extend %h3Bold;
        margin: 0;
        padding-left: 7px;
      }
    }

    .crudButtonsContainer {
      display: flex;
    }
  }

  .descriptionContainer {
    p {
      color: var(--grey-22);
      @extend %h5;
    }
  }
}
