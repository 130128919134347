@import "../../../styles/font.scss";

.panelWrapper {
    @extend %h5;
    line-height: var(--line-height-5);
    color: var(--dark-1);
    border-radius: var(--border-radius-2);
    border-color: var(--grey-4);
    z-index: 999999 !important;
  
    :global(.p-autocomplete-header) {
      background: white;
    }
  
    :global(.p-autocomplete-items .p-autocomplete-item) {
      border-radius: var(--border-radius-2);
      padding: var(--spacing-2);
    }
    :global(.p-autocomplete-items .p-autocomplete-item.p-highlight) {
      background: var(--orange);
      color: var(--primary);
    }
  
    :global(.p-autocomplete-header .p-autocomplete-filter-container svg) {
      left: var(--spacing-3);
    }
  
    :global(.p-autocomplete-header .p-autocomplete-filter-container input) {
      border-radius: unset;
      padding-left: 40px;
      padding-right: 12px;
      padding-bottom: var(--spacing-2);
      padding-top: var(--spacing-2);
      background-color: var(--grey-30);
      border-color: var(--grey-30);
  
      &:enabled:focus {
        box-shadow: none;
        border-color: var(--grey-30);
      }
    }
  
    :global(.p-autocomplete-trigger svg) {
      width: var(--spacing-3);
      height: var(--spacing-3);
    }
    :global(.p-autocomplete-trigger) {
      justify-content: flex-end;
      padding-right: var(--spacing-3);
    }
    :global(.p-autocomplete-label.p-inputtext),
    :global(.p-autocomplete-item) {
      padding: calc(var(--spacing-1) * 1.5);
      @extend %h5;
      line-height: var(--line-height-5);
    }
    :global(.p-autocomplete-items) {
      padding: var(--spacing-3);
    }
  }