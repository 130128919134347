@import "../../styles/font.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: var(--black-8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;

  @media (max-width: 767px) {
    z-index: 1 !important;
  }
}

.main_container {
  border-bottom: 1px solid var(--grey-12);
}

.header_container {
  height: var(--spacing-7);
  color: white;
  font-weight: bold;
  padding: var(--spacing-3);

  i {
    display: none;
  }

  @media (max-width: 480px) {
    height: var(--spacing-9);

    i {
      display: block;
      cursor: pointer;

      &:after {
        content: "";
        height: 20px; //You can change this if you want smaller/bigger borders
        width: 1px;

        position: absolute;
        left: 36px;
        top: 10px; // If you want to set a smaller height and center it, change this value

        background-color: var(--white); // The color of your border
        opacity: 0.4;
        cursor: default;
      }
    }
  }
}

.footer {
  &_item {
    display: flex;
    align-items: center;
    height: 40px;
    color: var(--white);
    cursor: pointer;

    &.highlight {
      background-color: var(--red-2);
    }
  }
  &_icon {
    margin-left: var(--spacing-5);
    font-size: 14px;
  }
  &_title {
    margin-left: var(--spacing-4);
    text-transform: uppercase;
    @extend %h6Bold;
  }
}

.content_container {
  height: 90%;
}

.accordion_container {
  height: var(--spacing-7);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--grey-12);
  // border-bottom: 1px solid var(--grey-12);
  transition: 0.4s;
  padding: 0 var(--spacing-4) 0 var(--spacing-4);
  cursor: pointer;

  &.isExpanded {
    border-bottom: 1px solid var(--grey-12);
  }
}

.accordion_header {
  color: var(--yellow-2);
  text-transform: uppercase;
  @extend %h6Bold;
}

.accordion_icon {
  font-size: var(--spacing-3);
  color: var(--white);

  &_container {
    width: 10%;
    transition: 0.5s;
  }
}

.accordion_section {
  &_container {
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    cursor: pointer;
  }

  &_indicator {
    width: 3px;
    height: var(--spacing-6);
    margin: var(--spacing-3) 0 var(--spacing-3) 0;
  }

  &_title {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: var(--spacing-4);
    color: var(--white);
    @extend %h6Bold;
  }

  &_spacing {
    height: 10px;
    width: 100%;
    cursor: initial;
  }

  &_icon {
    display: flex;
    margin-left: var(--spacing-4);
    color: var(--white);

    &_badge {
      // position: absolute;
      // top: -4px;
      // right: -10px;
      min-width: 16px;
      height: 16px;
      padding: var(--spacing-1);
      border-radius: 50%;
      background-color: var(--red-2);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      margin-left: var(--spacing-1);

      @extend %h6Bold;
    }
  }
}

.isSelected {
  color: var(--yellow-2);
}
