@import "../../styles/font.scss";

.container {
  :global(.p-dialog-content::-webkit-scrollbar) {
    display: none; /* Safari and Chrome */
  }

  :global(.p-dialog-content) {
    @media (max-width: 767px) {
      padding: 0 var(--spacing-2) !important;
    }

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    padding-top: 0 !important;
    padding-bottom: 75px !important;
  }

  :global(.p-dialog-header) {
    padding-top: var(--spacing-2) !important;
    padding-bottom: 0 !important;
    display: flex;
  }

  :global(.p-dialog-footer) {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  @media (max-width: 767px) {
    width: 90vw !important;
    margin: 0px;
  }

  height: 60vh !important;
  margin-top: 80px;
  background-color: var(--white) !important;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    :global(.p-dialog-title) {
      @extend %h4Bold;
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--black-2);
    }
    :global(.p-dialog-header-icon) {
      color: var(--black-2);
      &:enabled:hover {
        background: none;
        color: var(--black-2);
      }
      &.p-link,
      &:focus {
        box-shadow: none;
      }
    }
  }

  .customFooterContainer {
    display: flex;
    justify-content: flex-end;
  }

  .addComment {
    border-bottom: 1px solid var(--grey-3);
    .customFooterContainer {
      gap: var(--spacing-2);
      margin: var(--spacing-2) 0;
    }
  }

  :global(.p-dialog-footer) {
    @media (max-width: 767px) {
      padding-left: var(--spacing-2) !important;
      padding-right: var(--spacing-2) !important;
    }

    padding: 6px 26px 9px !important;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
  }

  .empty {
    @extend %h5;
  }
}
