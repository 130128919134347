@import "../../../styles/font.scss";

.container {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  border: none;
  border-radius: var(--border-radius-6);
  color: var(--primary);
  background-color: var(--orange);
  padding: var(--spacing-2) var(--spacing-3);
  height: fit-content;
  cursor: pointer;
  @extend %h5SemiBold;

  &.secondary {
    color: var(--primary);
    background-color: var(--white);
    border: 0.4px solid var(--black);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  i {
    font-size: var(--spacing-3);
  }
}
