@import "../../styles/font.scss";

:global {
  :local(.card) {
    .p-card {
      height: 100%;

      .p-card-title {
        @extend %h3;
        font-weight: var(--font-weight-600);
      }
    }

    .p-datatable {
      .p-datatable-wrapper {
        .p-datatable-tbody {
          tr {
            background-color: var(--white);
            &:hover {
              background-color: inherit !important;
            }
          }
        }
      }
      .p-datatable-thead {
        display: none;
      }
    }
  }
}

.container {
  padding: var(--spacing-5);
  width: 100%;

  @media (max-width: 480px) {
    padding: 0;

    .titleContainer {
      padding: var(--spacing-5);
    }
  }

  .titleContainer {
    .title {
      @extend %h2;
      margin-bottom: var(--spacing-5);
    }
  }

  .content {
    margin-top: var(--spacing-4);

    @media (max-width: 480px) {
      margin-top: 0;
    }

    .header {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-4);
      margin-bottom: var(--spacing-4);
    }

    .main {
      width: 100%;
      position: relative;

      .card {
        width: 100%;
      }
    }

    .card {
      position: relative;
      width: 50%;

      .labelvalue {
        display: flex;
        align-items: flex-end;

        &:not(:last-child) {
          margin-bottom: var(--spacing-3);
        }

        .label {
          width: 20%;
          @extend %h4SemiBold;
        }
        .value {
          @extend %h4;
        }
      }

      .input {
        &:not(:last-child) {
          margin-bottom: var(--spacing-3);
        }
      }

      &_title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          @extend %h3Bold;
        }
      }
    }
  }
  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-1);
    opacity: 0.4;
    z-index: 999999;

    i {
      font-size: var(--spacing-5);
    }
  }
}
