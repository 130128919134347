%h1 {
  font-family: "Helvetica";
  font-weight: var(--font-weight-400);
  font-size: var(--spacing-6);
  line-height: var(--spacing-7);
}

%h2 {
  font-family: "Helvetica";
  font-weight: var(--font-weight-400);
  font-size: var(--spacing-5);
  line-height: var(--line-height-5);
}

%h3 {
  font-family: "Helvetica";
  font-weight: var(--font-weight-400);
  font-size: var(--spacing-4);
  line-height: var(--line-height-5);
}

%h3Bold {
  font-family: "Helvetica";
  font-weight: var(--font-weight-600);
  font-size: var(--spacing-4);
  line-height: var(--line-height-5);
}

%h4 {
  font-family: "Helvetica";
  font-weight: var(--font-weight-400);
  font-size: calc(var(--spacing-3) + (var(--spacing-1) * 0.5));
  line-height: var(--line-height-5);
}

%h4SemiBold {
  font-family: "Helvetica";
  font-weight: var(--font-weight-600);
  font-size: calc(var(--spacing-3) + (var(--spacing-1) * 0.5));
  line-height: var(--line-height-5);
}

%h4Bold {
  font-family: "Helvetica";
  font-weight: var(--font-weight-700);
  font-size: calc(var(--spacing-3) + (var(--spacing-1) * 0.5));
  line-height: var(--line-height-4);
}

%h5 {
  font-family: "Helvetica";
  font-weight: var(--font-weight-400);
  font-size: var(--spacing-3);
  line-height: var(--line-height-4);
}

%h5MicroBold {
  font-family: "Helvetica";
  font-weight: var(--font-weight-500);
  font-size: var(--spacing-3);
  line-height: var(--line-height-4);
}

%h5SemiBold {
  font-family: "Helvetica";
  font-weight: var(--font-weight-600);
  font-size: var(--spacing-3);
  line-height: var(--line-height-3);
}

%h5Bold {
  font-family: "Helvetica";
  font-weight: var(--font-weight-700);
  font-size: var(--spacing-3);
  line-height: var(--line-height-5);
}

%h6 {
  font-family: "Helvetica";
  font-weight: var(--font-weight-400);
  font-size: var(--line-height-2);
  line-height: var(--line-height-3);
}

%h6Bold {
  font-family: "Helvetica";
  font-weight: var(--font-weight-600);
  font-size: var(--line-height-2);
  line-height: var(--line-height-3);
}

%h7 {
  font-family: "Helvetica";
  font-weight: var(--font-weight-400);
  font-size: var(--spacing-2);
  line-height: var(--line-height-3);
}
