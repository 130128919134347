@import "../../styles/font.scss";

.filter {
  // &_container {
  //   position: relative;
  // }
  &_header_container {
    height: var(--spacing-7);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.4s;
    background-color: var(--grey-13);
    padding: 0 var(--spacing-4);
    cursor: pointer;

    @media (max-width: 480px) {
      display: none;
    }
  }

  &_header_mobile {
    display: none;
    padding: 0 var(--line-height-2);

    @media (max-width: 480px) {
      display: flex;
      justify-content: space-between;
    }

    &_cta {
      display: flex;
      gap: var(--spacing-3);
    }
  }

  &_title {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
    &_icon {
      font-size: var(--spacing-3);
      color: var(--accent);

      &.hasFilters {
        color: var(--yellow)
      }
    }
    span {
      @extend %h5;
    }
  }

  &_icon {
    color: var(--black);

    &_container {
      transition: 0.5s;
    }
  }

  &_content {
    width: 100%;
    // position: absolute;
    // height: 0;
    // overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: var(--grey-13);
    // z-index: 99999;

    @media (max-width: 480px) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999999;
      height: 100%;
      // overflow-y: auto;
      background-color: var(--white);
    }
  }

  &_body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-3);
    padding: var(--spacing-4);
    border-top: 1px solid var(--grey-4);

    &_title {
      display: none;

      @media (max-width: 480px) {
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--spacing-2);
      }
    }

    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      height: calc(100% - 64px);
      overflow-y: auto;
    }
  }

  &_footer {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-3);
    width: 100%;
    padding: var(--spacing-4);
    padding-top: 0;

    @media (max-width: 480px) {
      position: absolute;
      bottom: 0;
      padding-top: var(--spacing-4);
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }

  &_badge {
    // position: absolute;
    // top: -4px;
    // right: -10px;
    min-width: 16px;
    height: 16px;
    padding: var(--spacing-1);
    border-radius: 50%;
    background-color: var(--red-2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    margin-left: var(--spacing-1);

    @extend %h6Bold;
  }
}
