@import "../../../styles/font.scss";

.container {
  @media (max-width: 580px) {
    width: 80vw !important;
  }

  @media only screen and (max-width: 925px) and (min-width: 581px) {
    width: 50vw !important;
  }

  @media (min-width: 926px) {
    width: 30vw !important;
  }

  background-color: var(--white) !important;

  .header {
    padding: var(--spacing-3);

    :global(.p-dialog-title) {
      @extend %h3Bold;
      text-transform: uppercase;
      color: var(--black-2);
    }

    // :global(.p-dialog-header-icons) {
    //   width: 11px;
    //   height: 11px !important;
    // }
  }

  :global(.p-dialog-content) {
    padding: 0px var(--spacing-3) var(--spacing-3) var(--spacing-3) !important;
  }

  .description {
    @extend %h4;
    color: var(--grey-22);
    flex-grow: 0 !important;
  }

  .footerContainer {
    display: flex;
    justify-content: flex-end;
  }

  :global(.p-dialog-footer) {
    padding: var(--spacing-3);
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
  }
}
