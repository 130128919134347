@import "../../styles/font.scss";

.container {
  @media (max-width: 767px) {
    width: 80vw !important;
  }
  @media only screen and (max-width: 925px) and (min-width: 767px) {
    width: 50vw !important;
  }

  background-color: var(--white) !important;

  .header {
    :global(.p-dialog-title) {
      display: flex;
      align-items: center;
      color: var(--black-2);
      justify-content: center;
    }
  }

  .description {
    @extend %h4SemiBold;
    color: var(--grey-22);
    flex-grow: 0 !important;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  :global(.p-dialog-header) {
    padding-top: var(--spacing-3);
  }

  :global(.p-dialog-content) {
    padding: var(--spacing-3);
  }

  :global(.p-dialog-footer) {
    padding: var(--spacing-3);
  }
}

.footerContainer {
  display: flex;
  justify-content: flex-end;
}
