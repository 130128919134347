@import "../../styles/font.scss";

:global {
  :local(.tooltip) {
    .p-tooltip-text {
      @extend %h5;
    }
  }
}

.container {
  padding: var(--spacing-5);
  width: 100%;

  &.withPanelDetails {
    width: 45%;
    padding-right: 0;

    @media (max-width: 768px) {
      width: 0;
    }
  }

  @media (max-width: 480px) {
    padding: 0;

    .titleContainer {
      padding: var(--spacing-1);
    }
  }

  .title {
    @extend %h2;
    margin-bottom: var(--spacing-5);

    @media (max-width: 480px) {
      font-size: 20px;
      padding: var(--spacing-4) var(--spacing-3);
      margin-bottom: var(--spacing-3);
    }
  }

  .content {
    border: 1px solid var(--grey-14);
    border-radius: 5px;
    background-color: var(--white);
    margin-top: var(--spacing-3);

    &.withPanelDetails {
      border-right: none;
      border-top-right-radius: unset;
    }

    @media (max-width: 480px) {
      border: none;
      margin-top: 0;
    }
  }

  .header {
    .headerCta {
      position: relative;
    }
    .headerCtaMobile {
      display: flex;
      gap: var(--spacing-4);
      @media (max-width: 480px) {
        display: none;
      }
    }
    .cta {
      display: flex;
      gap: var(--spacing-4);
      padding-right: var(--spacing-5);
      position: absolute;
      right: 0;
      top: 16px;

      @media (max-width: 1024px) {
        position: relative;
        padding: 0 0 var(--spacing-5) 0;
        justify-content: center;
      }

      @media (max-width: 480px) {
        justify-content: start;
        left: 14px;
        // position: absolute;
        // top: -38px;
        // left: 210px;
        .download {
          display: none;
        }
      }
    }
  }

  .errorBox {
    width: 100%;
    border-radius: var(--border-radius-4);
    background-color: var(--red-2);
    color: var(--white);
    padding: var(--spacing-4);
    margin-bottom: var(--spacing-4);

    &_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--spacing-3);

      &_title {
        @extend %h3;
      }

      &_close {
        cursor: pointer;
      }
    }

    &_content {
      @extend %h4;
      margin-bottom: var(--spacing-2);
    }
  }
}
