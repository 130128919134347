@import "../../styles/font.scss";

:global {
  :local(.tooltip) {
    .p-tooltip-text {
      @extend %h5;
    }
  }
}

:global {
  :local(.flagSelect) {
    padding: 0;
    button {
      border: none;
      padding: 5px 12px 5px 5px;

      @media (max-width: 480px) {
        padding: 0;
        &:after {
          display: none;
        }
        &[aria-expanded="true"] {
          &:after {
            display: none;
          }
        }
      }

      &[aria-expanded="false"],
      &[aria-expanded="true"] {
        &:after {
          position: absolute;
          top: 11px;
          right: 11px;
          border: none;
          font-family: "primeicons";
          content: "\e902";
        }
      }
    }

    ul {
      margin: 0;
      border: none;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      right: -5px;
      top: 34px;

      li {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.navbar_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  z-index: 3;

  .navbar_container_left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: var(--spacing-8);
    width: 292px;
    height: 100%;
    background: linear-gradient(
      270deg,
      var(--grey-11) 0%,
      rgba(246, 246, 246, 0) 100%
    );
    &:after {
      content: "";
      display: block;
      position: relative;
      left: 48px;
      border-bottom: 20px solid var(--white);
      border-left: 16px solid transparent;
      border-top: 26px solid transparent;
      border-right: 20px solid var(--white);
    }

    i {
      display: none;
      cursor: pointer;
    }

    @media (max-width: 480px) {
      width: 40%;
      padding-right: 0;
      padding-left: var(--spacing-3);
      background: var(--white);
      justify-content: flex-start;
      gap: var(--spacing-4);

      i {
        display: block;
        position: relative;

        &:after {
          content: "";
          height: 26px; //You can change this if you want smaller/bigger borders
          width: 1px;

          position: absolute;
          right: -9px;
          top: -4px; // If you want to set a smaller height and center it, change this value

          background-color: var(--grey-18); // The color of your border
          cursor: default;
        }
      }
    }
  }

  .logout {
    padding: 0 var(--spacing-4);
    cursor: pointer;
    @media (max-width: 480px) {
      padding: 0 var(--spacing-2);

      &.languagesEnabled {
        padding: 0 var(--spacing-2) 0 0;
      }
    }
  }

  .user {
    @media (max-width: 480px) {
      width: 28px;
    }
  }
}

.navbar_container_right {
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 480px) {
    width: 60%;
    gap: var(--spacing-3);
    &.languagesEnabled {
      gap: var(--spacing-1);
    }
  }
}

.mock_profile_divider {
  width: 1px;
  height: 19px;
  background-color: #e3e3e3;
  margin-right: 14px;
}

.mock_profile_label {
  font-size: 12px;
}

.mock_profile_icon {
  width: 28px;
  height: 28px;
  background-color: #d65532;
  border-radius: 50%;
  margin-left: 8px;
}
