@import "../../../styles/font.scss";

.container {
  border: 1px solid var(--grey-4);
  border-radius: var(--border-radius-2);
  transition: 0.4s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-3) 0;
  margin: 0 var(--spacing-4);
  cursor: pointer;

  &.hasBorder {
    padding-bottom: var(--spacing-3);
    border-bottom: 1px solid var(--grey-3);
  }

  .iconButtonTitleContainer {
    display: flex;
    align-items: center;

    .iconButtonContainer {
      padding-left: var(--spacing-3);

      i {
        font-size: 13px;
        color: var(--grey-130);
      }
    }
  }

  .title {
    @extend %h5Bold;
    color: var(--grey-130);
  }

  .headerRight {
    display: flex;
    align-items: center;
    gap: var(--spacing-3);

    .cta {
      @extend %h6;
      color: var(--black-4);
      text-decoration: underline;
    }
  }
}

.content {
  // max-height: 5000px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  &.hide {
    max-height: 0;
  }

  .children {
    padding: var(--spacing-3) var(--spacing-4);
    padding-top: 0;
  }
}
