@import "../../styles/font.scss";

:global {
  :local(.table) {
    .p-datatable-wrapper {
      .p-datatable-thead {
        tr {
          th {
            padding: 0;
          }
        }
      }
    }
  }
}

:global {
  :local(.tooltip) {
    .p-tooltip-text {
      @extend %h5;
    }
  }
}

.container {
  padding: var(--spacing-5);
  padding-top: var(--spacing-6);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: 100px;

  @media (max-width: 480px) {
    padding: var(--spacing-1);
    padding-top: var(--spacing-6);
  }

  table tr td:nth-child(1) {
    width: 30px;
    padding-right: 0px !important;
  }

  .crudContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .crudSpacing {
      margin-right: var(--spacing-3);
      display: flex;
      align-items: center;
    }
  }

  .accordionContent {
    overflow-y: scroll;
    max-height: 400;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .accordionSpacing {
      margin-bottom: var(--spacing-3);

      &:last-child {
        margin-bottom: var(--spacing-3);
      }
    }
  }

  .placholderPercentage {
    border-radius: var(--border-radius-4);
    padding: var(--spacing-1) var(--spacing-5);
    background-color: var(--yellow);
    color: var(--black)
  }

  .spinner {
    i {
      font-size: var(--spacing-5);
    }
  }
}
