@import "../../../styles/font.scss";

:global {
  :local(.container) {
    .p-multiselect:not(.p-disabled).p-focus {
      box-shadow: unset;
    }
  }
  :local(.panelWrapper) {
    // .p-multiselect-panel 
    .p-multiselect-items {
      padding: 0;
      .p-multiselect-item {
        &:not(.p-highlight):not(.p-disabled):hover {
          background-color: var(--white);
        }
        &.p-highlight {
          background-color: var(--white);
          color: var(--dark-1);
          box-shadow: none;
        }
        &:focus {
          box-shadow: none;
        }
        .p-checkbox {
          display: flex;
          align-items: center;
          .p-checkbox-box {
            border-radius: var(--border-radius-2);
            border: 1px solid var(--black-3);
            width: var(--spacing-3);
            height: var(--spacing-3);
            &.p-highlight {
              border: none;
              background: var(--accent);
            }
            svg {
              width: var(--line-height-2);
              height: var(--line-height-2);
            }
          }
        }
        
      }
    }
  }
}
.wrapper {
  width: 100%;
}
.wrapper,
.panelWrapper {
  @extend %h5;
  line-height: var(--line-height-5);
  color: var(--dark-1);
  border-radius: var(--border-radius-2);
  border-color: var(--grey-4);
  z-index: 999999 !important;

  :global(.p-multiselect-trigger svg) {
    width: var(--spacing-3);
    height: var(--spacing-3);
  }
  :global(.p-multiselect-trigger) {
    justify-content: flex-end;
    padding-right: var(--spacing-3);
  }
  :global(.p-multiselect-label),
  :global(.p-multiselect-item) {
    padding: calc(var(--spacing-1) * 1.5);
    @extend %h5;
    line-height: var(--line-height-5);
  }
  :global(.p-multiselect-items) {
    padding: 0;
  }
  :global(.p-multiselect-header) {
    display: none;
  }
}

// .panelWrapper {
//   :global(.p-dropdown-items) {
//     padding: 0;
//   }
// }
