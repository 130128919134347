.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: var(--spacing-5);
  height: var(--spacing-5);
  color: var(--primary);
  background-color: var(--orange);
  border-color: var(--orange);
  cursor: pointer;

  i {
    font-size: var(--spacing-3);
    color: var(--white);
  }

  &.secondary {
    color: var(--primary);
    background-color: var(--white);
    border: 0.4px solid var(--black);

    i {
      color: var(--black);
    }
  }
}
