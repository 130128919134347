@import "../../styles/font.scss";

.container {
  :global(.p-dialog-content::-webkit-scrollbar) {
    display: none; /* Safari and Chrome */
  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  background-color: var(--white) !important;
  height: 60vh !important;
  margin-top: 80px;

  @media (max-width: 767px) {
    width: 90vw !important;
    margin: 0px;
  }

  @media only screen and (max-width: 925px) and (min-width: 767px) {
    width: 70vw !important;
  }

  :global(.p-dialog-header) {
    @media (max-width: 767px) {
      padding: 0 var(--spacing-2) !important;
    }

    padding-top: var(--spacing-2) !important;
    padding-bottom: var(--spacing-2) !important;

    // :global(.p-dialog-header-icons) {
    //   width: 35px;
    // }
  }

  .header {
    :global(.p-dialog-title) {
      @extend %h4Bold;
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--black-2);
    }

    :global(.p-dialog-header-icon) {
      color: var(--black-2);
      &:enabled:hover {
        background: none;
        color: var(--black-2);
      }
      &.p-link,
      &:focus {
        box-shadow: none;
      }
    }
  }

  :global(.p-dialog-content) {
    @media (max-width: 767px) {
      padding: 0 var(--spacing-2) !important;
    }

    padding-top: 0 !important;
    padding-bottom: var(--spacing-2) !important;
  }
}
