@import "../../styles/font.scss";

.container {
  @media (max-width: 767px) {
    width: 80vw !important;
    margin: 0px;
  }

  @media only screen and (max-width: 925px) and (min-width: 767px) {
    width: 70vw !important;
  }

  :global(.p-dialog-header) {
    padding: var(--spacing-3) !important;
  }

  .header {
    :global(.p-dialog-title) {
      @extend %h4Bold;
      color: var(--black-2);
      text-transform: uppercase;
    }
    :global(.p-dialog-header-icon) {
      color: var(--black-2);
      &:enabled:hover {
        background: none;
        color: var(--black-2);
      }
      &.p-link,
      &:focus {
        box-shadow: none;
      }
    }

    // :global(.p-dialog-header-icons) {
    //   width: 11px !important;
    //   height: 11px !important;
    // }

    border-bottom: 1px solid var(--grey-3);
  }

  :global(.p-dialog-content) {
    padding: var(--spacing-3);

    .inputTextAreaContainer {
      width: 100%;
      display: flex;

      :global(.p-inputtextarea) {
        flex-grow: 1;
      }
    }
  }

  .descriptionContainer {
    @extend %h5;
  }

  :global(.p-dialog-footer) {
    padding: var(--spacing-3) !important;
    padding-top: 0px !important;

    .customFooterContainer {
      display: flex;
      justify-content: center;
    }
  }
}
