@import "../../../styles/font.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  .label,
  .value {
    width: 30%;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .label {
    @extend %h5;
  }
  .value {
    @extend %h5Bold;
    display: flex;
    gap: var(--spacing-3);
    width: 70%;

    &_empty {
      font-weight: 400;
      font-style: oblique;
    }

    &_link {
      color: var(--accent);
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: var(--spacing-2);
    i {
      font-size: var(--spacing-3);
      &.save {
        font-size: var(--spacing-4);
      }
    }
  }
}
