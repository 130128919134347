@import "../../styles/font.scss";

.container {
  display: flex;
  border: 1px solid var(--grey-31);
  border-radius: var(--spacing-1);
  margin-bottom: var(--spacing-2);
  background-color: var(--white);

  .leftContainer {
    p {
      @extend %h5Bold;
      color: var(--grey-190);
      margin: 5px 12px 0;
    }
  }

  .centerContainer {
    flex: 1;
    padding: 5px 0;
    position: relative;

    .divider {
      position: absolute;
      width: 1px;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: var(--grey-31);
      margin: 10px 0;
    }

    .infoContainer {
      display: flex;
      align-items: center;

      .subtitle {
        @extend %h5Bold;
        color: var(--grey-190);
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 12px;
      }

      .infoValue {
        @extend %h6;
        color: var(--grey-190);
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 5px;
      }
    }
  }

  .rightContainer {
    display: flex;
    justify-content: center;
    margin: var(--spacing-1) var(--spacing-2);

    .buttonContainer {
      button {
        margin-top: 0;
        border-radius: var(--spacing-1);
        border: 0.4px solid var(--grey-31);
        background: var(--white);
        padding: 0;
        cursor: pointer;

        p {
          @extend %h5;
          margin: 5px 10px;
          color: var(--grey-190);
          text-transform: uppercase;
        }
      }
    }
  }
}
