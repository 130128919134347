.container {
  padding: var(--spacing-5);
  padding-top: var(--spacing-6);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: 100px;

  .mainData {
    width: 100%;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .tableData {
    width: 100%;
  }

  .childrenWrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-3);
  }

  @media (max-width: 480px) {
    padding: var(--spacing-1);
    padding-top: var(--spacing-6);
  }
}
