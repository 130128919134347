@import "../../styles/font.scss";

:global {
  :local(.containerDialog) {
    .p-dialog {
      .p-dialog-header {
        .p-dialog-header-icon {
          color: var(--black-2);
          &:enabled:hover {
            background: none;
            color: var(--black-2);
          }
          &.p-link,
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.container {
  @media (max-width: 767px) {
    width: 80vw !important;
    height: 50vh !important;
  }
  background-color: var(--white) !important;

  :global(.p-dialog-header) {
    padding-top: var(--spacing-2) !important;
    padding-bottom: 0 !important;
    display: flex;
    border-bottom: 1px solid var(--grey-3);
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    :global(.p-dialog-title) {
      @extend %h4Bold;
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--black-2);
      // margin-bottom: var(--spacing-2);
    }
  }

  .description {
    @extend %h6;
    color: var(--grey-22);
    flex-grow: 0 !important;
  }

  .content {
    @extend %h5;
    margin-top: var(--spacing-4);
  }
}

.footerContainer {
  display: flex;
  justify-content: flex-end;
}
