.container {
  display: flex;
  height: 100%;

  .imgContainer {
    display: flex;
    height: 100%;
    align-items: center;
    width: 70%;
    background-color: var(--yellow);
  }
  .loginContainer {
    width: 30%;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .img {
    width: 100%;
  }
  .button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
