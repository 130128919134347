@import "../../../styles/font.scss";

:global {
  :local(.container) {
    .p-calendar-w-btn-right {
      .p-button {
        background: var(--white);
        border-color: var(--grey-4);
        border-left: none;
        color: var(--black);
        padding: 0;

        &:focus {
          box-shadow: none;
        }
      }
      .p-datepicker-trigger {
        border-radius: unset;
      }
    }
  }
}
.inputWrapper {
  @extend %h5;
  line-height: var(--line-height-5);
  color: var(--dark-1);
  border-radius: var(--border-radius-2);
  border-color: var(--grey-4);
  border-right: none;
  padding: calc(var(--spacing-1) * 1.5);
  width: 100%;

  &:enabled {
    &:focus,
    &:hover {
      box-shadow: none;
      border-color: var(--grey-4);
    }
  }
}

.fromToContainer {
    display: flex;
    gap: var(--spacing-3);
}

.panelWrapper {
  z-index: 999999 !important;
}