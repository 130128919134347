@import "../../styles/font.scss";

.container {
  // :global(.p-dialog-content::-webkit-scrollbar) {
  //   display: none; /* Safari and Chrome */
  // }

  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: none; /* Firefox */

  background-color: var(--white) !important;
  // height: 60vh !important;
  margin-top: 80px;
  max-height: 97%;

  @media (max-width: 767px) {
    width: 90vw !important;
    margin: 0px;
  }

  @media only screen and (max-width: 925px) and (min-width: 767px) {
    width: 70vw !important;
  }

  :global(.p-dialog-header) {
    @media (max-width: 767px) {
      padding: 0 var(--spacing-2) !important;
    }

    padding-top: var(--spacing-2) !important;
    padding-bottom: var(--spacing-2) !important;

    // :global(.p-dialog-header-icons) {
    //   width: 35px;
    // }
  }

  :global(.p-dialog-header .p-dialog-header-icons) {
    padding-top: 14px;
  }

  .header {
    :global(.p-dialog-title) {
      @extend %h4Bold;
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--black-2);
    }

    :global(.p-dialog-header-icon) {
      color: var(--black-2);
      &:enabled:hover {
        background: none;
        color: var(--black-2);
      }
      &.p-link,
      &:focus {
        box-shadow: none;
      }
    }
  }

  :global(.p-dialog-content) {
    @media (max-width: 767px) {
      padding: 0 var(--spacing-2) !important;
    }

    padding-top: 0 !important;
    padding-bottom: var(--spacing-2) !important;
  }

  .pdfContainer {
    .pdfDocument {
      .pdfPage {
        display: flex;
        justify-content: center;
        position: relative;
        // border: 1px solid;
      }
    }
  }

  .pdfPaginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__left {
      display: flex;
      gap: var(--spacing-3);
    }
  }

  .pdfSpinner {
    position: absolute;
    opacity: 0.4;
    background: var(--white);

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-2);
    cursor: move;
    border: 1px solid var(--black);
    padding: var(--spacing-3);
    position: absolute;
    border-radius: var(--border-radius-2);
    color: var(--black);
    width: 250px;
    height: 50px;

    @extend %h4;

    i {
      cursor: pointer;
    }
  }
}
