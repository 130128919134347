@import "../../../../styles/font.scss";

:global {
  :local(.wrapper) {
    .p-datatable-wrapper {
      .p-datatable-emptymessage {
        @extend %h6;
      }
      .p-datatable-thead {
        tr {
          th,
          th.p-highlight {
            background: var(--white);
            color: var(--primary);
            box-shadow: none;
            padding: var(--spacing-2) var(--spacing-3);
            @extend %h5SemiBold;

            span {
              white-space: nowrap;
            }

            svg {
              display: none;
            }

            &.p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
              background: var(--white);
              color: var(--primary);
            }

            .p-column-header-content {
              &::after {
                font-family: "primeicons";
                margin-left: var(--spacing-2);
                font-size: var(--spacing-2);
              }
            }
            &[aria-sort="ascending"] {
              .p-column-header-content {
                &::after {
                  content: "\e91c";
                }
              }
            }
            &[aria-sort="descending"] {
              .p-column-header-content {
                &::after {
                  content: "\e919";
                }
              }
            }
          }
        }
      }
      .p-datatable-tbody {
        tr {
            td {
                @extend %h5;
                line-height: var( --line-height-3);
                color: var(--grey-130);
                padding: var(--spacing-3) var(--spacing-3);
                border: none;
            }
        }
      }
    }
  }
}
