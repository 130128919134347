@import "../../../styles/font.scss";

:global {
  :local(.container) {
    .p-tabview {
      .p-tabview-panels {
        padding: 0 0;
      }
      .p-tabview-nav-container {
        width: 40%;
        padding: var(--spacing-5);
        padding-bottom: 0;

        @media (max-width: 1024px) {
          width: 100%;
        }
        @media (max-width: 480px) {
          padding: var(--spacing-1);
          padding-top: var(--spacing-5);
        }
      }
    }
  }
}

.container {
  &.tabViewNavContainerFull {
    :global(.p-tabview-nav-container) {
      width: 100%;
    }
  }
  :global(.p-tabview-nav) {
    border: none;

    :global(.p-tabview-ink-bar) {
      background-color: var(--yellow);
      height: 6px;
      width: 50% !important;
    }

    :global(.p-unselectable-text) {
      display: flex;
      justify-content: center;
      min-width: 125px;

      width: 50%;
      position: relative;

      &:first-child {
        &:after {
          content: "";
          height: 30%; //You can change this if you want smaller/bigger borders
          width: 1px;

          position: absolute;
          right: 0;
          top: 4px; // If you want to set a smaller height and center it, change this value

          background-color: var(--grey-15); // The color of your border
        }
      }

      :global(.p-tabview-nav-link:not(.p-disabled):focus) {
        box-shadow: none;
      }

      :global(.p-tabview-nav-link) {
        background: transparent !important;
        border-bottom: none;
        padding-top: 0;

        &:hover {
          border: #dee2e6;
          transition: none;
        }
      }

      :global(.p-tabview-title) {
        color: var(--black-9);
        text-transform: uppercase;
        @extend %h5MicroBold;
      }
    }
  }
}
